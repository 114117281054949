import React, { useEffect, useState } from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import useWindowDimensions from "../components/hooks/useWindowDimensions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import Carousel from "nuka-carousel";
import arrow_medium_right from "../assets/arrow_medium_right.svg";
import arrow_medium_left from "../assets/arrow_medium_left.svg";

import "../styles/templates/aboutme.sass";

const AboutMe = ({ pageContext, location }) => {
  const { slugref } = pageContext;
  const { home, aboutme } = pageContext.data.data;
  const { innerHeight, innerWidth } = useWindowDimensions();
  const [toggle, setToggle] = useState(false);

  if (typeof window === `undefined`) return <></>;
  return (
    <Layout
      location={location}
      data={home}
      toggle={toggle}
      setToggle={setToggle}
      slugref={slugref}
    >
      <section
        className="pageOption posabs"
        style={{ height: innerHeight - 109 }}
      >
        <div
          to={`/${home.locale !== "de" ? `${home.locale}/` : ""}${
            home.slug[0].portfolio
          }`}
          href="#"
          className="option bg-back"
          data-inf=""
          style={{
            width: `${0.5 * innerWidth +
              324.7543 +
              (0.725 * innerHeight - 456.75)}px`,
            left: 0,
            backgroundColor: "#d6c4a8",
            opacity: 0.7,
          }}
        ></div>
        <div></div>
      </section>
      <section>
        <article className="aboutme___text-container">
          <div className="aboutme___text-wrap">
            <div className="intro_container_wrap">
              <div className="intro_wrap">
                <div
                  className="intro"
                  data-sal="slide-up"
                  data-sal-delay="300"
                  data-sal-easing="ease"
                  data-sal-duration="1000"
                  dangerouslySetInnerHTML={{
                    __html: aboutme.introNode.childMarkdownRemark.html,
                  }}
                ></div>
              </div>
            </div>
            <div
              className="motto"
              data-sal="slide-up"
              data-sal-delay="600"
              data-sal-easing="ease"
              data-sal-duration="1000"
            >
              <blockquote>
                <h2 author-quote={`\u2015 ${aboutme.quoteAuthor}`}>
                  <FontAwesomeIcon
                    icon={faQuoteLeft}
                    size="5x"
                    color="gainsboro"
                  />
                  &nbsp;&nbsp;{aboutme.quote}
                </h2>
              </blockquote>
            </div>
            <Carousel
              defaultControlsConfig={{
                nextButtonText: " ",
                prevButtonText: " ",
                nextButtonStyle: {
                  backgroundColor: "transparent",
                  color: "black",
                  background: `url(${arrow_medium_right}) no-repeat center center`,
                  backgroundSize: "cover",
                  height: 50,
                  width: 30,
                  margin: 0,
                  padding: 0,
                },
                prevButtonStyle: {
                  backgroundColor: "transparent",
                  color: "black",
                  background: `url(${arrow_medium_left}) no-repeat center center`,
                  backgroundSize: "cover",
                  height: 50,
                  width: 30,
                  margin: 0,
                  padding: 0,
                },
                pagingDotsStyle: {
                  fill: "#a07fca",
                },
                pagingDotsStyle: { visibility: "hidden" },
              }}
            >
              <div className="cover">
                <img
                  data-sal="slide-up"
                  data-sal-delay="900"
                  data-sal-easing="ease"
                  data-sal-duration="1000"
                  src={aboutme.picture.fluid.src}
                />
              </div>
              {aboutme.aboutme.map((item, index) => (
                <p>
                  {item.bio}
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </p>
              ))}
            </Carousel>
          </div>
        </article>
      </section>
    </Layout>
  );
};

export default AboutMe;
